.popAddress {
    border-radius: 15px;
    text-align: center;
}

.titleAddress{
    text-align: center;
    font-size: 22px;
    font-weight: bold;
    margin-top: -20px;
    padding: 15px;
    border-radius: 15px 15px 0px 0px;
    color: #fff;
    background-color: $c1;
}

.userAddress{
    font-size: 25px;
}

.popAddress button {
    background-color: #ff4d4d; 
    color: white;
    border: none;
    padding: 10px 20px;
    border-radius: 5px;
    cursor: pointer;
    font-size: 16px;
    transition: background-color 0.3s ease;
    margin-left: 5px; 
}

.popAddress button:hover {
    background-color: #e60000; 
}

.popAddress a button {
    background-color: #ff4d4d; 
}