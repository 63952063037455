.user-history {
    background-color: #fafafa;
    padding: 25px 0px;
    min-height: 100vh;

    .user-history-header {
        font-size: 22px;
        display: flex;
        align-items: center;
        margin: 0;
        margin-bottom: 15px;
        margin-right: 8%;
        color: $c1;

        img {
            margin-left: 5px;
            height: 35px;
        }

    }

    .list-history {
        display: flex;
        flex-direction: row;
        flex-wrap: wrap;
        justify-content: flex-start;

        .no-products {
            margin: 15px;
        }

        .item {
            width: 20%;
            height: 370px;
            background-color: #fff;
            border-radius: 20px;
            margin: 10px 10px;
            padding-bottom: 10px;

            .header-history {
                padding: 8px 15px;
                background-color: $c1;
                border-radius: 20px 20px 0px 0px;
                color: #fff;
                font-size: 15px;
            }

            .details-history {
                padding: 0px 10px;

                p {
                    margin: 0;
                    font-size: 12px;
                }

                .price {
                    margin: 10px 0px;
                }

                .status {
                    color: $c1;
                    font-weight: bold;
                    font-size: 14px;
                }

                .btn {
                    color: #000;
                    border: 2px solid #000;
                    padding: 2px 14px;
                    border-radius: 15px;
                    background-color: #fff;
                    display: block;
                    margin: 6px auto;

                    &:hover {
                        background-color: $c1;
                        border: 2px solid $c1;
                        color: #fff !important;
                    }

                    &.active {
                        color: $c1;
                    }

                }

                .btn-click {
                    background-color: $c1;
                    border: 2px solid $c1;
                    color: #fff;
                    padding: 2px 14px;
                    border-radius: 15px;
                    display: block;
                    margin: 6px auto;

                    &:hover {
                        background-color: #fff;
                        border: 2px solid $c1;
                        color: $c1;
                    }
                }
            }
        }

        .show-items-list {
            background-color: #fff;
            overflow: auto;
            max-height: 90vh;
            border: 2px solid #e6e6e6;
            margin: 5px;
            width: 33%;

            .header {
                font-size: 13px;
                font-weight: 100;
                background-color: #e6e6e6;
                text-align: center;
            }

            th {
                padding: 7px;
            }

            td {
                text-align: center;
                border-top: 1px solid #e6e6e6;
            }

            .price {
                width: 65px;
            }

            .product {
                display: flex;
                align-items: center;

                img {
                    width: 20%;
                    padding: 0px 10px;
                }

                .product-data {
                    display: flex;
                    flex-direction: column;
                    width: 80%;

                    .description {
                        text-align: start;
                        margin: 0;
                        width: 100%;
                        color: $c1;

                    }

                    .prod-name {
                        display: none;
                    }

                    .prod-sale {
                        margin: 0;
                    }
                }

            }

            .total-sum {
                color: $c1;
                font-weight: bold;

                td {
                    margin: 10px 0px;
                }

                .num-prod {
                    color: #000;
                    width: 30px;
                    height: 30px;
                    border: 1px solid #000;
                    border-radius: 50%;
                    margin: auto;
                    margin-top: 10px;
                    display: flex;
                    align-items: center;
                    justify-content: center;

                }
            }
        }
    }
}