.products-view {
    .open-filter {
        display: none;
    }

    .side {
        width: 12%;
        background-color: #e6e6e6;
        position: fixed;
        overflow-x: hidden;
        overflow-y: auto;
        height: 77vh;

        .category-nav {
            width: 100%;
            margin-right: 5px;

            .category-title {

                padding: 2px 0px;
                margin-top: 30%;
                color: $c1;
                font-weight: bold;
                font-size: 18px;
            }

            ul {
                padding: 0px;
                margin: 0px;
                font-size: 18px;
                font-weight: bold;
                text-align: right;
                color: #e3032e;
                list-style-type: none;
            }
        }

        .category-name {
            padding-top: 10px;
            display: flex;
            flex-direction: column;

            .name {
                &.check {
                    background-color: #fff;
                    border-radius: 0px 30px 30px 0px;
                    margin: 3px 0px;
                    padding: 1px 6px;

                    span {
                        color: $c1;
                        font-weight: bold;
                    }
                }
            }

            a {
                text-decoration: none;
                color: $text;
                margin-right: 5px;

                span {
                    font-weight: 400;
                    font-size: 16px;
                }
            }

            .sub-category-name {
                margin-right: 15px;
                padding-top: 6px;

                a {
                    text-decoration: none;
                    color: $text;

                    span {
                        font-size: 14px;
                    }
                }

                &.checked {
                    background-color: #fff;
                    border-radius: 0px 30px 30px 0px;
                    margin: 3px 0px;
                    padding: 1px 15px;

                    span {
                        color: $c1;
                        font-weight: bold;
                    }
                }
            }
        }

        .filters-wrapper {
            margin-top: 5px;
            margin-right: 5px;

            .category-filter {
                .filter-title {
                    color: $c1;
                    font-size: 18px;
                    font-weight: bold;
                }

                .item {
                    padding-bottom: 16px;

                    &:last-child {
                        border-bottom: none;
                    }

                    .wrapper {

                        h2 {
                            color: $c1;
                            font-size: 16px;
                            font-weight: bold;
                            margin: 0px;
                            padding: 0px;
                        }

                        .wrap {
                            padding-top: 5px;

                            ul {
                                list-style-type: none;
                                padding: 0px;
                                margin: 0px;

                                li {
                                    color: $text;
                                    font-size: 16px;
                                    position: relative;
                                    padding: 4px 0;

                                    &.active {
                                        opacity: 1 !important;

                                        .input {
                                            border: 1px solid transparent;
                                            background: $c1;
                                        }
                                    }

                                    &[data-class="disabled"] {
                                        opacity: 0.5;
                                    }

                                    span {
                                        display: inline-block;
                                        padding-right: 20px;

                                        &.quantity {
                                            padding-right: 10px;
                                            color: $c2;
                                        }
                                    }

                                    .input {
                                        background: transparent;
                                        border: 1px solid #ccc;
                                        width: 15px;
                                        height: 15px;
                                        position: absolute;
                                        top: 4px;
                                        right: 0;
                                        cursor: pointer;

                                        img {
                                            width: 90%;
                                            margin: 0 auto;
                                            display: block;
                                        }
                                    }
                                }
                            }
                        }
                    }
                }
            }
        }
    }

    .category-card {
        margin-right: 13%;
    }
}

.col-lg-9 {
    display: flex;
    flex-wrap: wrap;
}

.category-name {
    width: 100%;

    h1 {
        padding-right: 10px;
    }
}

.no-style {
    display: contents;
}
