.header-cart {
    width: 30%;
    position: fixed;
    z-index: 999;
    top: 210px;
    left: 0;
    background: #fff;
    box-shadow: 1px 0 4px 1px rgb(0 0 0 / 0.2);
    transform: translateY(0%);
    transition: transform 0.5s;
    transition: transform 0.5s;
    direction: rtl;
    font-family: $font-open-sans-hebrew;


    &.closed {
        transform: translateY(-142%);
    }

    .header-cart-wrap {
        margin: 21px 0px;
        position: relative;

        .products {
            max-height: calc(100vh - 252px);
            overflow-y: auto;
            overflow-x: hidden;
            padding-left: 8px;
            scroll-behavior: smooth;

            &::-webkit-scrollbar-track {
                background-color: #f5f5f5;
            }

            &::-webkit-scrollbar-thumb {
                background-color: #ccc;
                border: 1px solid #555555;
            }

            &::-webkit-scrollbar {
                width: 5px;
                background-color: #f5f5f5;
            }

            .item-mini-cart {
                border: 1px solid #f5f5f5;

                &:hover {
                    .quantity {
                        .add-to-cart {
                            .wrap {
                                border: 1px solid #f5f5f5;
                                background-color: $c1;

                                p {
                                    color: #fff;
                                }
                            }

                            .wrap>div {
                                input {
                                    color: #fff;
                                }
                            }
                        }
                    }
                }
            }

            tr {
                display: flex;
                align-items: center;
            }

            .delivery-price{
                display: flex;
                margin: 0px 5px;
                align-items: center;
                justify-content: space-between;
            }

            .img-item-td {
                width: 20%;

                .img-item {
                    background-image: url(#{$get-path-to-assets}products/product.jpg);
                    width: 100%;
                    height: 60px;
                    background-size: 65px;
                    background-repeat: no-repeat;
                    background-position: 50% 50%;
                }
            }

            .prod-info-and-name {
                width: 53%;

                p {
                    margin: 1px 0px;
                }
                .sale{
                    font-size: 14px;
                    color: $c1;
                }
            }

            .quantity {
                width: 10%;

                .add-to-cart {
                    height: 100%;
                    padding: 0px;
                    margin: 0px;
                    width: 100%;

                    .wrap {
                        height: 100%;
                        display: flex;
                        padding: 0px;
                        margin: 0px;
                        flex-direction: column;
                        border: 1px solid #f5f5f5;
                        background-color: #fff;

                        p {
                            color: $c1;
                            font-size: 18px;
                            font-weight: bolder;
                        }
                    }

                    .wrap>div {
                        width: 100%;

                        input {
                            font-size: 16px;
                            font-weight: bold;
                            line-height: 1.2;
                            letter-spacing: 0.45px;
                            text-align: center;
                            color: #1d1d1b;
                            padding: 0px;
                            margin: 2px 0px;
                        }

                    }
                }
            }

            .price-details {
                width: 25%;
                text-align: end;
                margin: 0px 5px;

            .price {
                font-size: 18px;
                margin: 0px;
                span{
                    font-size: 12px;
                }
            }
            .sale{
                margin: 0;
                font-size: 12px;
                color: $c1;
            }
        }

        .comment-warning {
            font-size: 12px;
            color: $c1;
        }

        .delete-item {
            text-align: center;
          }
          
          .delete-btn {
            background: none;
            border: none;
            color: #FF0000; /* צבע אדום לאייקון */
            cursor: pointer;
            font-size: 30px; /* גודל האייקון */
          }
          
          .delete-btn:hover {
            opacity: 0.7;
          }
          
          .delete-icon {
            font-size: 30px; /* גודל האייקון */
          }
    }
}
}