.sub-navbar {
  position: relative;
  padding: 0 40px;
  border-top: 1px solid #e7e7e7;
  border-bottom: 1px solid #e7e7e7;

  &.level-1 {
    background-color: #d55656;
  }
  
  &.level-2 {
    background-color: #d57676; 
  }
}

.custom-scroll-wrapper {
  overflow: visible !important;
}

.custom-scroll-container {
  margin: 0 !important;
}

.sub-navbar-item {
  color: #333;
  text-decoration: none;
  padding: 10px 15px;
  white-space: nowrap;
  font-size: 14px;
  border: 1px solid #e7e7e7;
  margin-left: -1px;
  display: flex;
  align-items: center;
  
  .sub-navbar.level-2 & {
    color: #000;
  }
}

.sub-navbar-item:hover,
.sub-navbar-item.active {
  color: #000;
  font-weight: bold;
  background-color: #fff;
}

.custom-arrow-btn {
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  background: transparent !important;
  border: none !important;
  cursor: pointer;
  padding: 5px;
  width: auto !important;
  height: auto !important;
  z-index: 1;
}

.custom-arrow-btn:hover {
  background-color: rgba(0, 0, 0, 0.1) !important;
}

.custom-arrow-btn.left-arrow {
  left: 5px;
}

.custom-arrow-btn.right-arrow {
  right: 5px;
}

.custom-arrow-btn img {
  width: 20px;
  height: 20px;
}

.rotate-90 {
  transform: rotate(90deg);
}

.rotate-270 {
  transform: rotate(270deg);
}
