.new-image{
    .search{
        display: flex;
        flex-direction: row;
        align-items: center;
        width: 100%;
        margin: 20px 6px 5px 6px;
        .input-class{
            input{
                height: 35px;
                width: 200px;
                border: 1px solid $c1;
                border-radius: 5px;
                outline: none;
                font-size: 16px;
                padding: 0 5px;
            }
        }
    }
    .image-list{
        margin-top: 15px;
        display: flex;
        flex-direction: row;
        flex-wrap: wrap;
        align-items: center;
        width: 100%;
    }
    button{
        border: 2px solid $c1;
        font-size: 17px;
        padding: 15px 2px;
        width: 15%;
        text-align: center;
        height: 85px;
        margin: 2px 5px;
        cursor: pointer;
        border-radius:5px;
        &:hover{
            background-color:$c1;
            color:#fff !important;
        }
    }
}