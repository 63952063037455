.img-load {
	&.active {
		.load-image-wrapper {
			top: 0;
			right: 0;
			bottom: 0;
			left: 0;
			transform: none;

			input {
				width: 100%;
				height: 100%;
			}

			&:before {
				content: "";
				position: absolute;
				top: 8px;
				right: 8px;
				width: 32px;
				height: 32px;
				background: rgba(0, 0, 0, 0.4) url(#{$get-path-to-assets}icons/change.svg);
				background-size: 24px;
				background-repeat: no-repeat;
				background-position: 50% 50%;
				z-index: 0;
			}
		}

		.addImg-custom {
			position: relative;
			height: 100%;
			opacity: 0;
		}
	}

	img.main-img {
		border: 1px solid #f5f5f5;
		width: 300px;
	}

	img.main-img:hover {
		border: 1px solid #f5f5f5;
		width: 300px;
	}
}

$bg: url('data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABAAAAAQAQMAAAAlPW0iAAAAA3NCSVQICAjb4U/gAAAABlBMVEXMzMz////TjRV2AAAACXBIWXMAAArrAAAK6wGCiw1aAAAAHHRFWHRTb2Z0d2FyZQBBZG9iZSBGaXJld29ya3MgQ1M26LyyjAAAABFJREFUCJlj+M/AgBVhF/0PAH6/D/HkDxOGAAAAAElFTkSuQmCC');

.img-load {

	margin: 5px;
	position: relative;
}

.load-image-wrapper {
	position: absolute;
	top: 50%;
	left: 50%;

	.addImg-custom {
		display: flex;
		justify-content: space-between;
		align-items: center;
	  
		.add-image-text {
		  text-align: left;
		}
	  
		.plus {
			position: relative;
			width: 30px;
			height: 30px;
			border-radius: 50%;
			background-color: $c1;
			margin-left: 10px;
			cursor: pointer;
		  
			img {
			  width: 50%;
			  height: 50%;
			  position: absolute;
			  top: 8px;
			  left: 8px;
			  cursor: pointer;

			}
		  
			input {
			  position: absolute;
			  top: 0;
			  left: 0;
			  width: 100%;
			  height: 100%;
			  opacity: 0;
			  cursor: pointer;
			}
		}
}		  
	  
	&.dragged {
		background: #fff;
	}

	.choose-opt {
		position: absolute;
		top: 0;
		right: 0;
		bottom: 0;
		left: 0;
		width: 100%;
		height: 100%;
		background: rgba(0, 0, 0, 0.7);
		z-index: 9;

		button {
			background: transparent;
			outline: none;
			cursor: pointer;
			border: none;

			img {
				display: block;
				width: 16px;
				margin-top: 12px;
				margin-right: 8px;
			}
		}

		ul {
			position: absolute;
			top: 50%;
			transform: translateY(-50%);
			right: 0;
			left: 0;

			li {
				position: relative;
				display: inline-block;
				width: 50%;
				cursor: pointer;

				&.upload {
					input {
						position: absolute;
						z-index: 99;
						opacity: 0;
						top: 0;
						right: 0;
						bottom: 0;
						left: 0;
						width: 100%;
						height: 100%;
						cursor: pointer;
					}
				}

				img {
					display: block;
					width: 40px;
					margin: 0 auto;
				}

				span {
					display: block;
					color: #fff;
					text-align: center;
					margin-top: 8px;
				}
			}
		}
	}

	.addImg {
		position: absolute;
		top: 50%;
		transform: translateY(-50%);
		right: 0;
		left: 0;
		text-align: center;
		cursor: pointer;
		z-index: 9;

		&:hover {
			img {
				transform: rotate(360deg);
			}
		}

		ul {
			display: inline-block;
			background: rgba(0, 0, 0, 0.35);
			padding: 8px 16px;
			border-radius: 4px;
			box-shadow: 2px 2px 4px #2a2a2a;

			li {
				display: inline-block;
				vertical-align: middle;

				img {
					width: 32px;
					transition: all 0.5s;
				}

				span {
					display: inline-block;
					padding-right: 8px;
				}
			}
		}

		h4 {
			font-size: 18px;
			color: #fff;
			font-weight: 400;
			margin-bottom: 8px;
		}

		span {
			display: block;
			padding: 8px 0;
			color: #fff;
			font-size: 16px;
		}

		button {
			margin-bottom: 16px;
			position: relative;
			z-index: 100;
		}

		input {
			position: absolute;
			z-index: 99;
			opacity: 0;
			top: 0;
			right: 0;
			bottom: 0;
			left: 0;
			width: 100%;
			height: 100%;
			cursor: pointer;
		}

		img {
			position: relative;
			display: block;
			width: 64px;
			margin: 0 auto;
			cursor: pointer;
			filter: drop-shadow(2px 2px 6px #000);
		}
	}
}

.for-crop {
	max-height: 80vh;
	overflow-y: auto;
	overflow-x: hidden;
	position: relative;

	.box-2 {
		position: absolute;
		width: 80%;
		height: 80%;
		left: 50%;
		top: 50%;
		transform: translate(-50%, -50%);
		z-index: 999;
		pointer-events: none;
		border: 1px solid red;
	}

	.box-3 {
		position: absolute;
		width: 50%;
		height: 50%;
		left: 50%;
		top: 50%;
		transform: translate(-50%, -50%);
		z-index: 999;
		pointer-events: none;
		border: 1px solid green;
	}
}

.image-preview {
	padding: 6px 8px;
	background: #000 $bg;
	position: relative;
	height: auto; 
	max-width: 100%; 
	max-height: 100%; 
	display: flex;
	justify-content: center;
	align-items: center;

	img {
		max-width: 100%;
		max-height: 100%;
		object-fit: contain; 
	}
}

.actions {
	margin: 16px 10px;

	li {
		display: inline-block;

		button {
			padding: 8px 32px;
			border-radius: 4px;
			border: none;
			cursor: pointer;

			&.button-green {
				background: #09a6a7;
				color: #fff;
				font-size: 18px;
				margin-left: 16px;
				transition: all 0.5s;

				&:hover {
					background: darken(#09a6a7, 10%);
				}
			}

			&.button-red {
				background: #696969;
				font-size: 18px;
				color: #fff;
				transition: all 0.5s;

				&:hover {
					background: darken(#696969, 10%);
				}
			}
		}
	}
}

.cropper-container {
	font-size: 0;
	line-height: 0;
	position: relative;
	-webkit-user-select: none;
	-moz-user-select: none;
	-ms-user-select: none;
	user-select: none;
	direction: ltr;
	-ms-touch-action: none;
	touch-action: none;

	img {
		display: block;
		min-width: 0 !important;
		max-width: none !important;
		min-height: 0 !important;
		max-height: none !important;
		width: 100%;
		height: 100%;
		image-orientation: 0deg
	}
}

.cropper-wrap-box,
.cropper-canvas,
.cropper-drag-box,
.cropper-crop-box,
.cropper-modal {
	position: absolute;
	top: 0;
	right: 0;
	bottom: 0;
	left: 0;
}

.cropper-wrap-box {
	overflow: hidden;
}

.cropper-drag-box {
	opacity: 0;
	background-color: #fff;
}

.cropper-modal {
	opacity: .5;
	background-color: #000;
}

.cropper-view-box {
	display: block;
	overflow: hidden;
	width: 100%;
	height: 100%;
	outline: 1px solid #39f;
	outline-color: rgba(51, 153, 255, 0.75);
}

.cropper-dashed {
	position: absolute;
	display: block;
	opacity: .5;
	border: 0 dashed #eee
}

.cropper-dashed.dashed-h {
	top: 33.33333%;
	left: 0;
	width: 100%;
	height: 33.33333%;
	border-top-width: 1px;
	border-bottom-width: 1px
}

.cropper-dashed.dashed-v {
	top: 0;
	left: 33.33333%;
	width: 33.33333%;
	height: 100%;
	border-right-width: 1px;
	border-left-width: 1px
}

.cropper-center {
	position: absolute;
	top: 50%;
	left: 50%;
	display: block;
	width: 0;
	height: 0;
	opacity: .75
}

.cropper-center:before,
.cropper-center:after {
	position: absolute;
	display: block;
	content: ' ';
	background-color: #eee
}

.cropper-center:before {
	top: 0;
	left: -3px;
	width: 7px;
	height: 1px
}

.cropper-center:after {
	top: -3px;
	left: 0;
	width: 1px;
	height: 7px
}

.cropper-face,
.cropper-line,
.cropper-point {
	position: absolute;
	display: block;
	width: 100%;
	height: 100%;
	opacity: .1;
}

.cropper-face {
	top: 0;
	left: 0;
	background-color: #fff;
}

.cropper-line {
	background-color: #39f
}

.cropper-line.line-e {
	top: 0;
	right: -3px;
	width: 5px;
	cursor: e-resize
}

.cropper-line.line-n {
	top: -3px;
	left: 0;
	height: 5px;
	cursor: n-resize
}

.cropper-line.line-w {
	top: 0;
	left: -3px;
	width: 5px;
	cursor: w-resize
}

.cropper-line.line-s {
	bottom: -3px;
	left: 0;
	height: 5px;
	cursor: s-resize
}

.cropper-point {
	width: 5px;
	height: 5px;

	opacity: .75;
	background-color: #39f
}

.cropper-point.point-e {
	top: 50%;
	right: -3px;
	margin-top: -3px;
	cursor: e-resize
}

.cropper-point.point-n {
	top: -3px;
	left: 50%;
	margin-left: -3px;
	cursor: n-resize
}

.cropper-point.point-w {
	top: 50%;
	left: -3px;
	margin-top: -3px;
	cursor: w-resize
}

.cropper-point.point-s {
	bottom: -3px;
	left: 50%;
	margin-left: -3px;
	cursor: s-resize
}

.cropper-point.point-ne {
	top: -3px;
	right: -3px;
	cursor: ne-resize
}

.cropper-point.point-nw {
	top: -3px;
	left: -3px;
	cursor: nw-resize
}

.cropper-point.point-sw {
	bottom: -3px;
	left: -3px;
	cursor: sw-resize
}

.cropper-point.point-se {
	right: -3px;
	bottom: -3px;
	width: 20px;
	height: 20px;
	cursor: se-resize;
	opacity: 1
}

@media (min-width: 768px) {
	.cropper-point.point-se {
		width: 15px;
		height: 15px
	}
}

@media all and (max-width: 900px) {
	.crop-tool-wrapper {
		.flex-container {
			.col-lg-6 {
				width: 100%;

				&:last-child {
					display: none;
				}
			}
		}
	}

}

@media (min-width: 992px) {
	.cropper-point.point-se {
		width: 10px;
		height: 10px
	}
}

@media (min-width: 1200px) {
	.cropper-point.point-se {
		width: 5px;
		height: 5px;
		opacity: .75
	}
}

.cropper-point.point-se:before {
	position: absolute;
	right: -50%;
	bottom: -50%;
	display: block;
	width: 200%;
	height: 200%;
	content: ' ';
	opacity: 0;
	background-color: #39f
}

.cropper-invisible {
	opacity: 0;
}

.cropper-bg {
	background-image: $bg;
}

.cropper-hide {
	position: absolute;
	display: block;
	width: 0;
	height: 0;
}

.cropper-hidden {
	display: none !important;
}

.cropper-move {
	cursor: move;
}

.cropper-crop {
	cursor: crosshair;
}

.cropper-disabled .cropper-drag-box,
.cropper-disabled .cropper-face,
.cropper-disabled .cropper-line,
.cropper-disabled .cropper-point {
	cursor: not-allowed;
}