.prod-info-line {
    display: flex;
    position: relative;
    font-size: 11px;
    align-items: center;
	font-family: $font-open-sans-hebrew;

    .prod-brand {
        margin: 0;
    }

    .line {
        color: $c1;
    }
    .milky{
        color:  #0971f0;
        font-weight: bold;
    }
    .fleshy{
        color: #e3032e;
        font-weight: bold;
    }
    .parve{
        color: #28b014;
        font-weight: bold;
    }

    .weight-quantity {
        margin-left: 3px;
    }
   
}
.prod-name{
    font-size: 13px;
    max-width: 95%;
    margin: 0px;
    font-weight: 500;
	font-family: $font-open-sans-hebrew;
}

  