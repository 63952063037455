.select-place {
    height: 30px;
    width: 135px;
    margin: 15px;
    border-radius: 5px;
    font-size: 18px;
}

.add-place-head {


    .add-place {
        margin-right: 15px;


        .add-place-btn {
            display: flex;
            flex-direction: row;
            justify-content: flex-start;
            align-items: center;
            margin: 10px 0px;

            input {
                padding: 6px;
                font-size: 16px;
                width: 130px;
            }
            .add-cul-floor-btn{
                display: flex;
            }

            button {
                margin-right: 10px;
                background-color: $c1;
                border-radius: 10px;
                color: #fff;
                border: 1px solid $c1;
                padding: 10px;
                display: flex;
                align-items: center;
                font-size: 17px;

                img {
                    width: 20px;
                    margin-left: 10px;
                }

                &:hover {
                    color: $c1;
                    background-color: #fff;

                    img {
                        filter: invert(60%) sepia(93%) saturate(4553%) hue-rotate(341deg) brightness(110%) contrast(107%);
                    }
                }
            }
        }
    }
}

.place-insert {
    margin: 5px;

    label {
        margin: 5px;
    }

    input {
        width: 100px;
    }
}

.location {
    display: flex;
    flex-wrap: wrap;

    .location-column {
        max-width: 170px;
        margin: 3px;
        padding: 3px;

        .note {
            font-size: 8px;
        }


        .input-class {
            display: flex;
            justify-content: space-between;

            input {
                width: 50%;
            }
        }

        .select {
            justify-content: space-between;
            display: flex;
        }

        .btn-save-change {
            width: 100%;
            display: flex;
        }
        .add-btn-floor{
            display: flex;
        }
        .add-cul-floor-btn{
            display: flex;
        }

        button {
            width: 100%;
            display: flex;
            align-items: center;
            justify-content: center;
            height: 25px;
            font-size: 14px;
            img {
                width: 12px;
                margin-left: 10px;
                filter: invert(1);
            }
        }

        input {
            width: 95%;
        }

        .location-row {
            padding: 2px;
            border: 1px solid gray;
            margin: 2px;

            .location-select {
                display: flex;
                justify-content: space-between;

            }
        }

        h2 {
            margin: 0%;
            font-size: 18px;
        }
    }

    .location-box {
        display: flex;
        background-color: white;
        position: absolute;
        flex-wrap: wrap;
        padding: 10px 0px;
        z-index: 1;

        .box-details {
            border: 1px solid gray;
            padding: 3px;
            margin: 2px;
        }
    }
}