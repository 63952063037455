.history {
  background: #f6f7fb;
  min-height: 100vh;
  padding-bottom: 40px;

  .no-products {
    margin-bottom: 0;
    color: $text;
    font-weight: 400;
    text-align: center;
  }

  .items-container {
    background: #fff;
    padding: 24px;
    margin: 24px auto;

    .heading {
      position: relative;
      display: flex;
      align-items: center;

      .open-calendar {
        .react-calendar {
          position: absolute;
          right: 0;
          visibility: hidden;
          z-index: 999;
          border: 1px solid #ddd;
          box-shadow: 2px 2px 6px #ddd;

          &.active {
            visibility: visible;
            background-color: #fff;
          }
        }
      }




      button.toggle {
        background: #f5f5f5;
        border: 1px solid #ddd;
        border-radius: 4px;
        padding: 8px 16px;
        cursor: pointer;

        img {
          display: inline-block;
          vertical-align: middle;
          width: 32px;
        }

        span {
          display: inline-block;
          vertical-align: middle;
          color: $text;
          padding-right: 16px;
        }
      }

      .main-btn-cont {
        text-align: left;

        form {
          display: inline-block;
          position: relative;
          cursor: pointer;

          input {
            position: absolute;
            top: 0;
            left: 0;
            right: 0;
            bottom: 0;
            opacity: 0;
            width: 100%;
          }
        }

        .glb-btn-cls {
          cursor: pointer;
          background: $c1;
          margin: 0 10px;
          text-align: center;
          display: inline-block;
          padding: 10px;

          &:hover {
            background: darken($c1, 20%);
          }

          p {
            display: inline-block;
            color: #fff;
            margin: 0;
            vertical-align: middle;

          }

          img {
            display: inline-block;
            width: 20px;
            margin: 0 8px;
            vertical-align: middle;
          }
        }
      }

      .search {
        position: relative;
        width: 25%;
        margin-right: auto;

        input {
          border: 1px solid #dedede;
          height: 40px;
          box-sizing: border-box;
          width: 100%;
          padding-right: 8px;
          font-size: 16px;
        }

        img {
          position: absolute;
          width: 20px;
          top: 9px;
          left: 8px;

          &.close {
            cursor: pointer;
          }
        }
      }

    }
    .item {
      max-height: 60px;
      transition: all .5s;
      overflow: hidden;

      >.flex-container {
        padding: 8px 0;
      }

      &.active {
        max-height: none;
      }

      &:nth-child(2n) {
        >.flex-container {
          background: #f9f9f9;
        }
      }

      .flex-container {
        align-items: center;
        font-size: 14px;

        .name-col {
          p {
            padding-left: 15px;
          }
        }

        .col-approved {

          .approve-btn-1,
          .wrap {
            text-align: center;
          }

          p {
            color: blue !important;
            background: gray;
            padding: 5px 10px;
            display: inline-block;
            border-radius: .2rem;

            &.Blocked {
              color: red !important;
              background: gray;
            }

            &.Active {
              color: green !important;
              background: gray;
            }
          }
        }
      }

      p {
        margin: 0;
        color: #545454;

        &.price {
          &:before {
            content: "\20AA";
          }
        }
      }

      .img {
        cursor: pointer;

        img {
          display: block;
          width: 24px;
          margin: 0 auto;
        }
      }

      .comments {
        padding-top: 8px;

        p {
          color: #808080;
        }
      }

      .heading {
        .wrap {
          &.title {
            padding-right: 16px;
          }
        }
      }

      .xls-wrap {
        text-align: center;

        .xls-btn-small-cont {
          background: #e1e3ec;
          border-radius: .25rem;
          cursor: pointer;
          display: inline-block;
          position: relative;

          .xls-btn-icon {
            display: block;
            margin: 0 auto;
            max-width: 25px;
            padding: 5px;
          }

          &:hover {
            background: darken(#e1e3ec, 20%);

            img {
              filter: brightness(0) invert(1);
            }
          }
        }
      }

      .products {
        padding-bottom: 16px;
        border-bottom: 4px solid #e2e3e6;
        margin-bottom: 16px;

        .pay-btn {
          .wrap {
            text-align: left;

            p {
              display: inline-block;
              margin-left: 13px;
              background: $c1;
              padding: 5px 10px;
              color: #fff;
              cursor: pointer;

              &:hover {
                background: darken($c1, 10%);
              }
            }
          }
        }

        .product {
          margin: 16px 0;
          border-bottom: 1px solid #f5f5f5;
          padding-bottom: 16px;

          &:last-child {
            border-bottom: none;
            padding-bottom: 0;
            margin-bottom: 0;
          }

          .wrap {
            &.title {
              padding-right: 16px;

              >p {
                color: $text;
                font-weight: bold;
                margin-bottom: 8px;
              }

              p.option {
                margin: 0;
                font-size: 14px;

                span {
                  display: inline-block;

                  &.price {
                    font-size: 16px;

                    &:before {
                      content: "\20AA";
                      padding-right: 4px;
                    }
                  }

                  &.space {
                    width: 8px;
                  }
                }
              }
            }
          }

          .img {
            img {
              display: block;
              width: 100%;
            }
          }

          .comment {
            font-size: 14px;
          }
        }
      }
    }
  }

  table {
    width: 95%;
    margin: 0px auto;
    text-align: center;

    td {
      img {
        width: 35px;
        margin: 3px;
        padding-top: 10px;
      }
    }

    .product-list {
      background-color: #fff;
    }

    .product {
      background-color: #dfd9d9;
    }
  }

  .comment-popup {
    margin: 0px 20px;
  }
}