.private-area-list{
    padding-top: 65px;
    display: flex;
    flex-direction: column;
    a{
            display: flex;
            flex-direction: column;
            align-items: center;
            text-decoration: none;
            color: #000;
            padding: 3px 15px;
            margin-bottom: 10px;
            width: 155px;
            z-index: 2;
        }
    
}
.private-area{
    background-color: #fafafa;
    padding-bottom: 25px;
    .head{
        display: flex;
        margin-right: 8%;
        align-items: center;

        img{
            height: 30px;
        }
        .head-page {
            font-size: 25px;
            font-weight: 400;
            margin-right: 1%;
            text-align: right;
            color: $c1;
        }
    }
    .head-page {
        margin: 0;
        padding: 10px 0px;
        font-size: 25px;
        font-weight: 400;
        margin-right: 8%;
        text-align: right;
        color: $c1;
    }

    .details-window {
        margin-right: 8%;
        width: 60%;

        .header-window {
            display: flex;
            justify-content: space-between;
            align-items: center;
            border-radius: 30px 30px 0px 0px;
            background-color: $c1;

            h2 {
                font-size: 27px;
                font-weight: 400;
                margin: 0px;
                margin-right: 15px;
                text-align: right;
                padding: 10px 0px;
                color: #fff;
            }

            .btn-edit-details {
                color: #fff;
                border: 2px solid #fff;
                border-radius: 20px;
                padding: 5px 15px;
                margin: 10px 0px 10px 15px;
                font-weight: 400;
                text-decoration: none;
            }
        }

        .register-form {
            background-color: #fff;
            margin: 0px;
            padding-top: 25px;
            padding-bottom: 10px;
            margin-bottom: 25px;
            border-radius: 0px 0px 30px 30px;

            .details-form {
                width: 95%;

                input {
                    width: 100%;
                }
            }
        }
    }
}