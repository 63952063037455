.shop-cart {
    background-color: #fafafa;

    .checkbox-and-btn {
        padding: 25px 0px;
        display: flex;
        margin-right: 8%;
        width: 60%;
        align-items: center;
        justify-content: space-around;

        .checkbox-style {
            display: flex;
            flex-direction: column;
            align-items: flex-start;
            width: 50%;

            label {
                display: flex;
                font-size: 12px;
                margin-bottom: 5px;
                align-items: flex-start;
            }
        }

        .to-pay {
            padding: 8px 15px;
            width: 130px;
            font-size: 16px;
            font-weight: 400;
            border-radius: 25px;
            background-color: $c1;
            color: #fff;
            border: 2px solid $c1;

            cursor: pointer;

            &:hover {
                background-color: #fff;
                color: $c1;
                border: 2px solid $c1;
            }
        }
    }
}

