.pay-popup {
    text-align: center;

    .massage {
        text-align: center;
        margin: 5px;
    }

    .sum-to-pay {
        text-align: center;
        font-size: 22px;
        font-weight: bold;
        margin-top: -20px;
        padding: 15px;
        border-radius: 15px 15px 0px 0px;
        color: #fff;
        background-color: $c1;
    }

    .note {
        color: red;
        text-align: center;
        margin: 5px;
    }

    .num-payment {
        display: flex;
        align-items: center;
        justify-content: center;

        p {
            margin: 5px;
            font-size: 20px;
        }

        select {
            color: #fff;
            border: none;
            border-radius: 5px;
            width: 45px;
            font-size: 18px;
            height: 25px;
            text-align: center;
            background-color: $c1;
            margin-right: 10px;

            option {
                background-color: #fff;
                color: $c1;
            }
        }

    }

    .save-card {
        text-align: center;
        font-size: 20px;
        margin-bottom: 5px;
        display: flex;
        align-items: center;
        justify-content: center;
        flex-direction: row;
        p{
            margin: 0;
        }
    }

    .save-card-checkbox {
        display: flex;
        align-items: center;
        justify-content: center;
        flex-direction: row;

        label {
            margin-left: 5px;
        }
    }
        iframe {
        height: 335px;
        border: none;
    }
}