.station-place{
    .station-place-map{
        display: flex;
        flex-direction: row-reverse;
        justify-content: space-evenly;
    }
    .all-point{
        display: flex;
        flex-direction: column;
    }
    input{
        padding: 5px;
        margin: 2px;
        width: 150px;
    }

    .p-title {
        display: flex;
        align-items: center;
        margin: 5px 10px;
       

        input[type=number] {
            -moz-appearance: textfield;
        }
    }
    .btn {
        color: #fff;
        padding: 4px 15px;
        border-radius: 5px;
        margin: 0px;
        margin-bottom: 5px;
        margin-right: 5px;
        cursor: pointer;
        font-size: 16px;
        background-color: $c1;
        cursor: pointer;
        &:hover {
            border: 1px solid $c1;
            background-color: #fff;
            color: $c1 !important;
        }
    }

}
.marker-data{
    img{
        width: 25px;
    }
}
.add-point{
    margin-right: 10px;
}
.station-delivery{
    margin-right: 10px;
   margin-bottom: 15px;
}
    
