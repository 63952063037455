.home-page {
    .section-categories{
      .container{
        position:relative;
        h1 {
          display:inline-block;
          font-size: 32px;
          font-weight: 300;
          color: $text;
          background: #fff;
          padding-left: 24px;
          z-index: 1;
        }
        .line {
          position: absolute;
          top: 48px;
          height: 1px;
          background: #ddd;
          right: 0;
          left: 0;
          z-index: -1;
        }
        .sub-container{
          .wrapper{
            .wrapper-sub-cont{
              position:relative;
              margin:20px 20px;
              border: 1px solid #ddd;
              .img-cont{
                width:100%;
                min-height: 220px;
                img{
                  display:block;
                  width:70%;
                  margin:0 auto;
                }
              }
              .title-cont{
                position: absolute;
                bottom:0;
                left:0;
                width:100%;
                background: $c1;
                text-align: center;
                h3{
                  text-align: center;
                  color:#fff;
                  margin: 10px 0;
                  font-size:22px;

                }
                .btn-cont{
                  display:inline-block;
                  p{
                    border:2px solid #fff;
                    color:#fff;
                    padding: 5px 35px;
                    cursor:pointer;
                    &:hover{
                      background: #fff;
                      color: $c1;
                    }
                  }
                }
              }
            }
          }
        }
      }
    }
   
}
