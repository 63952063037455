.sub-footer{
    font-family: 'Open Sans Hebrew';
    background-color: #f2f2f2;
    display: flex;
    justify-content: space-around;
    img{
        width: 40px;
    }
    .click-and{
        display: flex;
        flex-direction: column;
        align-items: center;
        margin: 30px 0px;

        h3{
            margin: 2px 0px;
            font-size: 16px;
            font-weight: bold;
            color: $c1;
        }
        h5{
            font-size: 16px;
            font-weight: 300;
            line-height: 1.2;
            margin: 1px 0px;
            text-align: center;
            color: #000;
        }
    }
}
.footer {
    font-family: 'Open Sans Hebrew';
    position: relative;
    padding: 16px 0px;
    background-color: #e6e6e6;
    display: flex;
    justify-content: space-between;
    align-items: center;

    .legal-details{
        display: flex;
        margin: 1% 2%;
        a{
            color: #000;
            text-decoration: none;
            font-size: 14px;    
        }
    }
    .copyright {
        display: flex;
        flex-direction: column;
        font-size: 14px;
        line-height: 1.33;
        text-align: left;
        color: #000;
         margin: 1% 3%;
         img{
            width: 190px;
         }
    }
}
