
iframe {
    height: 335px;
    border: none;
}

@keyframes spin {
    from {
        transform: rotate(0deg);
    }
    to {
        transform: rotate(360deg);
    }
}
    
.paymentBtn {
    background-color: red;
    color: white;
    border: none;
    width: 100px;
    height: 50px;
    border-radius: 15%;
    font-size: 15px;
    cursor: pointer;
    position: fixed;
    bottom: 50px;
    left: 13%;
    transform: translate(-50%, 0);
    transition: background-color 0.3s ease;
}

.paymentBtn.disabled {
    width: 75px;
    height: 75px;
    border-radius: 50%;
    animation: spin 1s infinite linear;
    cursor: not-allowed;
    background-color: rgba(255, 0, 0, 0.6);
    color: rgba(255, 255, 255, 0.6);
}

.divPayStyle{
    position: absolute;
    top: 45%;
    left: 31.3%;
}

.textBox {
    -webkit-appearance: none;
    font-family: 'Assistant', sans-serif;
    font-size:large;
    color:black;
    width:100%;
    text-align:right;
    padding:6px;
    border:1px solid rgba(0,0,0,.125);
    border-radius:2px;
    box-sizing: content-box;
    -webkit-box-sizing: border-box;
    -moz-box-sizing: border-box; 
    margin-top:2px;
    outline: none;
    width: 300px;
    margin-left: 10%;
}

.txtStyle{
    text-align: right;
    min-width: 100px;
    margin-left: 48%;
    color: #808080;
    height: 335px;
    font-size:small;
}
