nav.sidebar {
    position: fixed;
    top: 212px;
    right: 0;
    bottom: 0;
    width: 300px;
    transform: translateX(300px);
    transition: transform 0.7s ease 0s;
    background: #fff;
    box-sizing: border-box;
    border-left: 1px solid #ddd;
    z-index: 99;
    height: -webkit-fill-available;
    overflow-y: scroll;
    font-family: $font-open-sans-hebrew;
    &.active {
      transform: translateX(0);
    }
  
    h2 {
      color: $text;
      text-align: center;
      margin: 0;
      padding: 12px 0;
      background: #f6f6f6;
      box-shadow: 1px 3px 0 1px #e2e3e6;
      font-size: 24px;
      font-weight: 400;
    }
  
    ul {
      padding: 0px;
      li {
        display: block;
        
        .btn-head, a {
          position: relative;
          display: block;
          padding: 16px;
          background: transparent;
          transition: all 0.5s;
  
          &:hover {
            background: #f6f6f6;
          }
  
          &.active {
            background: #eee;
          }
  
          img {
            height: 20px;
            display: inline-block;
            vertical-align: middle;
            padding-left: 16px;
          }
  
          span {
            color: $text;
            display: inline-block;
            vertical-align: middle;
            font-size: 16px;
          }
  
          .img {
            position: absolute;
            left: 0;
            top: 50%;
            transform: translateY(-50%);
            padding: 8px;
            background: transparent;
            transition: all 0.5s;
            border-radius: 50%;
  
            img {
              display: block;
              width: 8px;
            }
          }
        }
        .btn-head{
          border: none;
          background: none;
          width: 100%;
          display: flex;
        }
        .sub-menu{
          margin-right: 25px;
          background: #f6f6f6;
          &:hover{
            background: #c5c5c5;
          }
        }
      }
    }
  }