.product-details-edit {
    padding: 15px;

    .name-and-img {
        display: flex;
        width: 85%;
        margin: auto;

        .name-and-desc {
            display: flex;
            width: 50%;
            flex-direction: column;
        }

        .edit-img {
            margin-right: 15px;
        }
    }

    input,
    select {
        border: 1px solid #ddd;
        box-sizing: border-box;
        color: #000;
        font-size: 16px;
        padding-right: 16px;
        width: 100%;
        height: 40px;
        margin-bottom: 10px;
    }

    .product-search {
        position: relative;

        img.search {
            position: absolute;
            top: 10px;
            left: 8px;
            width: 20px;
        }
    }

    .check-box {
        display: flex;
        align-items: center;
        flex-direction: row;
        margin-top: 15px;
      

        .checkbox-style {
            span {
                display: flex;
                align-items: center;
                flex-direction:row-reverse;
            }

            label {
                padding: 0 2px 0 16px;

            }

            input {
                padding: 0;
                width: 35px;
                height: 35px;
            }
        }
    }

    .sort-field {
        position: absolute;
        left: 0;
    }

}


.tab-header {
    height: 45px;
    background-color: $c1;
    display: flex;

    .tab {
        width: 50%;
        background-color: $c1;
        border: none;
        font-size: 20px;
        color: #fff;
        height: 100%;
        text-align: center;
        text-decoration: none;
    }

    .tab.active {
        cursor: pointer;
        background-color: #fff;
        color: $c1;
        border-bottom: 0;
        width: 49%;
        margin-right: 1%;
        position: relative;
        border-radius: 15px 15px 0 0;
        margin-top: 8px;
    }


}

