.container {
    .prod-details {
        width: 88%;

        .search-row {
            align-items: center;
        }

        .input {
            display: flex;
            align-items: center;
            margin-top: 15px;
            &.mkt-or-barcode{
                margin-top: 10px;
                h2 {
                    width: 50%;
                }
            }
            .input-class {
                display: flex;
                flex-direction: row;
                align-items: center;
            }

            h2 {
                font-size: medium;
                font-weight: 400;
                display: inline-block;
                margin: 0px 0px 0px 5px;
            }

            label {
                align-items: center;
            }

            input {
                width: 100%;
                box-sizing: border-box;
                height: 30px;
                border: 1px solid #ddd;
                padding-right: 16px;
                font-size: 16px;
                color: $text;

            }

            input::-webkit-outer-spin-button,
            input::-webkit-inner-spin-button {
                -webkit-appearance: none;
                margin: 0;
            }

            input[type=number] {
                -moz-appearance: textfield;
            }
        }

        .input-btn {
            display: flex;
            margin-top: 10px;
        }

        .input-checkbox {
            display: flex;
            margin-top: 15px;

            h2 {
                font-size: medium;
                font-weight: 400;
                display: inline-block;
                margin: 0px 0px 0px 5px;
            }

            .show-unavailable-inventory {
                display: flex;
            }

        }

        .btn {
            color: #fff;
            padding: 5px 15px;
            border-radius: 5px;
            margin: 0px;
            margin-right: 5px;
            background-color: $c1;
            cursor: pointer;
            &:hover {
                border: 1px solid $c1;
                background-color: #fff;
                color: $c1 !important;
            }
        

        }

        .table-data {
            table {
                max-width: 1200px;
                align-items: center;
                width: 95%;
                padding: 20px 8px;

                th {
                    text-align: center;
                    background-color: $c1;
                    margin: 5px 0px;
                }

                td {
                    text-align: center;
                }
            }
        }

        .tr-from {
            background-color: #E0FFFF;
        }

        .tr-to {
            background-color: #FFFF99;
        }

        .quantity-to-add {
            display: flex;
            align-items: center;
            justify-content: space-around;

            .changes {
                width: 30%;
            }

            input {
                width: 60%;
            }

            input::-webkit-outer-spin-button,
            input::-webkit-inner-spin-button {
                -webkit-appearance: none;
                margin: 0;
            }

            input[type=number] {
                -moz-appearance: textfield;
            }
        }

        .select-stock-type {
            border: 0px;
            font-size: 16px;

            option {
                font-size: 16px;
                border: 0px;
            }
        }

        .save-btn {
            display: flex;
            align-items: center;
            justify-content: center;
            flex-direction: row;

            .btn {
                margin: 5px;
            }
        }
    }

    .option-details {
        width: 12%;

        .btn {
            font-size: 16px;
            width: 100%;
            height: 30px;
            color: $c1;
            border: 1px solid $c1;
            padding: 1px;
            margin: 3px 0px;
            border-radius: 5px;
            background-color: #fff;

            &:hover {
                background-color: $c1;
                color: #fff !important;
            }

            &.active {
                color: $c1;
            }

            &:disabled {
                background-color: #D0D0D0;
                color: white;
                border: 1px solid #D0D0D0;
            }
        }
    }
}

.saved-changes {
    background-color: white;
    border: 1px solid $c1;
    width: 250px;
    height: 100px;
    position: absolute;
    border-radius: 15px;
    top: 50%;
    left: 50%;
    font-size: 22px;
    text-align: center;
}

.product-detail,
.manual-location {
    display: flex;
    border: 2px solid $c1;
    border-radius: 4px;
    align-items: flex-start;
    flex-direction: column;
    padding: 0px 8px;
    position: absolute;
    right: 20px;
    bottom: 4px;
    width: 220px;
    margin-left: 10px;
    background-color: white;

    .close-btn {
        margin: 8px 0px 3px 0px;
        cursor: pointer;

        img {
            height: 15px;
            width: 15px;
        }
    }

    .crate-type,
    .location-type,
    .quantity-in-box,
    .gross_Weight,
    .quantity-in-manufacturer-box {
        display: flex;
        flex-direction: row;
        align-items: center;

        p {
            margin: 4px 0px;
            font-size: 16px;

        }

        input {
            margin-right: 5px;
            width: 45%;
        }

        .select {
            display: flex;
            flex-direction: row;
            align-items: center;
            margin: 5px 0px;

            h2 {
                margin: 4px 0px;
                font-size: 16px;
                font-weight: 100;
                margin: 0px;
                padding-left: 5px;
            }

            .select_option {
                height: auto;
            }
        }
    }

    .save-btn {
        margin: 10px;
        align-self: center;

        button {
            border-radius: 5px;
            border: 1px solid $c1;
            padding: 2px 20px;
            font-size: 18px;
            color: $c1;
            background-color: white;
            cursor: pointer;

            &:hover {
                color: white;
                background-color: $c1;
            }
        }
    }

    .all-inputs {
        display: flex;
        flex-direction: row;
        align-items: center;
        justify-content: center;
        margin: 6px 0px;
        width: 220px;
    }

    .input-class {
        display: flex;
        flex-direction: column;
        padding: 0px 2px;
        align-items: center;

        h2 {
            margin: 0px;
        }

        input {
            width: 50%;
        }
    }

    .product-in-box {
        h4 {
            margin: 12px 0px 4px 0px;
        }
    }
}

.manual-location {
    display: inline-block;
    width: max-content;
    max-width: 470px;

    .save-btn {
        text-align: center;
    }
}