.checkbox-style{
    display: flex;

    .form-control {
        font-family: system-ui, sans-serif;
        font-size: 2rem;
        font-weight: bold;
        line-height: 1.1;
        display: grid;
        grid-template-columns: 1em auto;
        gap: 0.5em;
      }
      
      .form-control + .form-control {
        margin-top: 1em;
      }
      
      .form-control--disabled {
        color: var(--form-control-disabled);
        cursor: not-allowed;
      }
      
      input[type="checkbox"] {
        -webkit-appearance: none;
        appearance: none;
        background-color: #fff;
        margin: 0;
        color: currentColor;
        border: 1px solid #e6e6e6;
        border-radius: 5px;
        transform: translateY(-0.075em);
        margin-left: 5px;
        display: grid;
        place-content: center;
      }
      
      input[type="checkbox"]::before {
        content: '\2714';
        color: $c1;
        font-size: 16px;
        padding: 0px 3px;
        transform: scale(0);
        transform-origin: bottom left;
        transition: 120ms transform ease-in-out;        
      }
      
      input[type="checkbox"]:checked::before {
        transform: scale(1);
      }
}